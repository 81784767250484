.tabelaInstaladores table{
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
}

.tabelaInstaladores{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tabelaInstaladores button{
    padding: 10px;
    width: 20%;
    font-size: 16px;
    background-color: #116feb;
    color: #FFF;
    border-radius: 10px;
    text-decoration:none;
    cursor: pointer;
}

table, tr, td{
    align-items: center;
    text-align: center;
    background-color: rgba(93, 89, 89, 0.177);
    padding: 4px;
}

.posicao{
    font-weight: bold;
    background-color: #191970;
}

.botaoBaixarArquivo{
    width: 30% !important;
    height: 100% !important;
    margin-top: 5px !important;
    padding: 3px !important;
}

.tabelaInstaladores a{
    cursor: pointer;
}