.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login h2{
    padding: 5px;
    justify-content: left;
    justify-self: left;
    align-self: flex-start;
    margin-left: 30%;
}

.login input{
    width: 40%;
    padding: 8px;
    border-radius: 10px;
}

.login button{
    margin-top: 10px;
    padding: 10px;
    width: 40%;
    font-size: 20px;
    background-color: #116feb;
    color: #FFF;
    border-radius: 10px;
    text-decoration:none;
    cursor: pointer;
}

.login a{
    width: 20%;
    height: 5%;
    padding: 5px;
    border-radius: 4px;
    font-size: x-small;   
}