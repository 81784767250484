footer{
    font-size: 8px;
    padding: 4px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: black;
    background-color: #FFF;
    text-align: center;
    opacity : 0.9;
}

footer a{
    color: black;
    text-decoration: none;
}