.not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
}

.not-found h1{
    font-size: 120px;
}

.not-found a{
    text-decoration: none;
    background-color: #116feb;
    color: #FFF;
    padding: 10px;
    margin-top: 14px;
}