.imagem{
	display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
}

.menu{
    margin: 20px;
    width: 20%;
}

.texto{
    width: 80%;
}

.texto img{
    max-width: -webkit-fill-available;
}

.menu h3{
    cursor: pointer;
}

.texto a{
    cursor: pointer;
}