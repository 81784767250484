header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    height: 60px;
    background-color: #FFF;
}