.tabelaUsers table{
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
}

.tabelaUsers{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.buttonAdicionar{
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 10px;
    width: 20%;
    font-size: 16px;
    background-color: beige;
    color: #000000;
    border-radius: 10px;
    text-decoration:none;
    cursor: pointer;
}

.buttonAdicionar:hover{
    background-color: rgb(37, 26, 166);
    color: #FFF;
  }

.buttonEditar{
    padding: 10px;
    width: 100%;
    font-size: 16px;
    background-color: beige;
    color: #000000;
    border-radius: 10px;
    text-decoration:none;
    cursor: pointer;
}

.buttonEditar:hover{
    background-color: rgb(37, 26, 166);
    color: #FFF;
  }

table, tr, td{
    align-items: center;
    text-align: center;
    background-color: rgba(93, 89, 89, 0.177);
    padding: 4px;
}

.posicao{
    font-weight: bold;
    background-color: #191970;
}
