.varBarResponsive{
  background-color: #FFF;
    width: 100%;
    margin: 0;
    padding: 0;
    opacity : 0.9;
}

.conNav{
    width: 100%;
  background-color: #FFF;
  opacity : 0.9;
}

.toolNav{
    width: 100%;
  background-color: #FFF;
    opacity : 0.9;
}

.varBarResponsive a{
    text-decoration: none;
    color: #000;
    opacity : 0.9;
}