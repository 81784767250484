.criarUsuario{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.checkboxdiv{
    display: flex;
    flex-direction: row;
    align-items: left;
    width: 100%;
}

.checkboxdiv h3{
    margin-top: 0%;
}

.criarUsuario h2{
    padding: 10px;
    justify-content: left;
    justify-self: left;
    align-self: flex-start;
}
.criarUsuario input{
    width: 100%;
    padding: 8px;
    border-radius: 10px;
}

.criarUsuario button{
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    font-size: 25px;
    background-color: #116feb;
    color: #FFF;
    border-radius: 10px;
    text-decoration:none;
    cursor: pointer;
}