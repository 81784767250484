*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font: 400 16px Lato, sans-serif;
  line-height: 1.8;
  background-color: #4B0082;
  text-align: justify;
  width: 100%;
  height: 100%;
}

.app{
  display: flex;
  margin: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerpage{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 18px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 40px;
  width: 90%;
  height: 100%;
  min-height: 600px;
  background-color: #FFF;
  opacity : 0.9;
}

.containerpage h3{
  margin-top: 20px;
}

.botoes{
  margin-top: 60px;
}

.botao{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  font-size: 20px;
  padding: 10px;
  border-radius: 15px;
  background-color: beige;
  color: #121212;
  cursor: pointer;
  transition: all 0.5s;
}

.botao:hover{
  background-color: rgb(37, 26, 166);
  color: #FFF;
}

.loaddingDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 18px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 18px;
  width: 96%;
  height: 100%;
  min-height: 635px;
  padding: 20px 8px;
  background-color: #FFF;
  opacity : 0.9;
}

.loaddingDiv img{
  width: 10%;
  height: 5%;
}